import {Settings} from 'luxon';
import {Accordion} from './Accordion.js';
import {agencyAnimations, lottieAnimations} from './animations/animations.js';
import {HeaderSearchForm, initVueComponents, Search, SearchResultsForm} from './components';
import {Dropdown} from './Dropdown';
import {AgencyMap} from './maps/AgencyMap';
import {Modal} from './Modal.js';
import {Nav} from './Nav.js';
import {ScrollInteraction} from './ScrollInteraction';
import {
    agencySlider,
    caseSlider,
    homeSlider,
    jobSlider,
    lastPostSlider,
    lastWorksSlider,
    pictoSlider,
    simpleSlider,
} from './sliders';
import {Tabs} from './Tabs';

// Load all SVGs to combine them into a sprite
require.context('../svg', true, /\.svg$/);

Settings.defaultLocale = window.CleverAge.lang;

initVueComponents({
    Search,
    HeaderSearchForm,
    SearchResultsForm,
});

const modals = document.querySelectorAll('.js-modal');
for (let modalEl of modals) {
    modalEl.modal = new Modal(modalEl);
}

const accordions = document.querySelectorAll('.js-accordion');
for (let accordionEl of accordions) {
    accordionEl.accordion = new Accordion(accordionEl);
}

new Dropdown(
    document.querySelector('.lang-dropdown'),
    document.querySelector('.lang-dropdown-current'),
    document.querySelector('.lang-dropdown-languages'),
);
new Dropdown(
    document.querySelector('.search-form-wrapper'),
    document.querySelector('.search-form-toggle'),
    document.querySelector('.search-form'),
);
new Nav();
new Tabs();
new ScrollInteraction();

document.querySelectorAll('.single-agency .agency-map').forEach(mapElem => new AgencyMap(mapElem));
document.querySelectorAll('.block-slider-home .splide').forEach(slider => homeSlider(slider));
document.querySelectorAll('.splide-case').forEach(slider => caseSlider(slider));
document.querySelectorAll('.splide-picto').forEach(slider => pictoSlider(slider));
document.querySelectorAll('.splide-simple').forEach(slider => simpleSlider(slider));
document.querySelectorAll('.splide-job').forEach(slider => jobSlider(slider));
document.querySelectorAll('.splide-last-post').forEach(slider => lastPostSlider(slider));
document.querySelectorAll('.splide-last-works').forEach(slider => lastWorksSlider(slider));
document.querySelectorAll('.splide-agency').forEach(slider => agencySlider(slider));

lottieAnimations();
agencyAnimations();
